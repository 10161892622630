import React from 'react';
import { TextField, Button } from '@mui/material';
import DateOfBirthField from '../SubComponent/DateOfBirthField';
import Loader from '../SubComponent/Loader';

const AccountDetailsForm = ({
  formValues,
  handleChange,
  handleDateChange,
  handleSaveChanges,
  errors,
  dateOfBirth,
  updateDisable,
  updateConsumerProfile,
}) => {
  return (
    <form className="m-0 self-stretch flex flex-col items-start pt-4 justify-start gap-[32px] max-w-full mq450:gap-[16px]">
      <h1 className="m-0 relative text-13xl leading-[48px] font-bold font-web-secondary-body1 text-m3-black text-left mq1050:text-7xl mq1050:leading-[38px] mq450:text-lgi mq450:leading-[29px]">
        Account details
      </h1>
      <div className="self-stretch h-px flex flex-row items-start justify-start pt-px px-0 pb-0 box-border max-w-full"></div>
      
      <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
        <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
          <TextField
            id="standard-basic"
            name="firstName"
            label="First Name"
            variant="standard"
            className="w-full h-full"
            inputProps={{ maxLength: 25 }}
            InputProps={{ style: { height: '100%' } }}
            InputLabelProps={{ style: { height: '100%' } }}
            value={formValues.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            sx={{
              '.MuiInputBase-root': { height: '100%' },
              '.MuiInputLabel-root': {
                transform: 'translate(0, 1.5px) scale(1)',
                '&.MuiInputLabel-shrink': { transform: 'translate(0, -1.5px) scale(0.75)' }
              },
              '.MuiFormControl-root': { height: '100%' }
            }}
          />
        </div>

        <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
          <TextField
            id="standard-basic"
            name="lastName"
            label="Last Name"
            variant="standard"
            className="w-full h-full"
            inputProps={{ maxLength: 25 }}
            InputProps={{ style: { height: '100%' } }}
            InputLabelProps={{ style: { height: '100%' } }}
            value={formValues.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            sx={{
              '.MuiInputBase-root': { height: '100%' },
              '.MuiInputLabel-root': {
                transform: 'translate(0, 1.5px) scale(1)',
                '&.MuiInputLabel-shrink': { transform: 'translate(0, -1.5px) scale(0.75)' }
              },
              '.MuiFormControl-root': { height: '100%' }
            }}
          />
        </div>
      </div>

      <div className="self-stretch flex flex-row items-start justify-start gap-[32px] max-w-full mq750:flex-wrap mq450:gap-[16px]">
        <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full">
          <TextField
            id="standard-basic"
            label="Email"
            variant="standard"
            className="w-full h-full"
            disabled
            InputProps={{ style: { height: '100%' } }}
            InputLabelProps={{ style: { height: '100%' } }}
            value={formValues.email}
           
            sx={{
              '.MuiInputBase-root': { height: '100%' },
              '.MuiInputLabel-root': {
                transform: 'translate(0, 1.5px) scale(1)',
                '&.MuiInputLabel-shrink': { transform: 'translate(0, -1.5px) scale(0.75)' }
              },
              '.MuiFormControl-root': { height: '100%' }
            }}
          />
        </div>

        <div className="flex-1 flex flex-col items-end justify-start gap-[32px] min-w-[265px] max-w-full mq450:gap-[16px]">
          <div className="self-stretch flex flex-row items-start justify-start max-w-full">
            <div className="h-[53px] flex-1 flex flex-col items-start justify-start pt-0 px-0 pb-[5.5px] box-border gap-[5.5px] max-w-full mq450:h-auto">
              <DateOfBirthField value={dateOfBirth} onChange={handleDateChange} />
            </div>
          </div>
          <Button
            className="w-[185px] h-[52px] shadow-[0px_2px_0px_rgba(0,_0,_0,_0.04)]"
            variant="contained"
            sx={{
              textTransform: "none",
              color: "#fff",
              fontSize: "18",
              background: "#f28500",
              borderRadius: "32px",
              "&:hover": { background: "#f28500" },
              width: 185,
              height: 52,
            }}
            onClick={handleSaveChanges}
            disabled={updateDisable}
          >
            {updateConsumerProfile === "isLoading" ? <Loader /> : "Save Changes"}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AccountDetailsForm;
