import { initializeApp } from "firebase/app";
import { getMessaging } from 'firebase/messaging';
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import {APIKEY,AUTHDOMAIN,PROJECT_ID,STORAGE_BUCKET,MESSAGING_SENDER_ID,APP_ID,MEASUREMENT_ID} from './Constants';

const firebaseConfig = {
  apiKey: APIKEY,
  authDomain: AUTHDOMAIN,
  projectId:  PROJECT_ID ,
  storageBucket: STORAGE_BUCKET,
  messagingSenderId: MESSAGING_SENDER_ID,
  appId: APP_ID,
  measurementId: MEASUREMENT_ID,
};
console.log("Firebase Config: ", firebaseConfig);
// // Initialize Firebase

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);
const firestore = getFirestore(app);
export { messaging };
export { firestore };
export const auth=getAuth();
export const db=getFirestore(app)
