import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import CustomDatePicker from './CustomDatePicker';
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';
import format from "date-fns/format";
import { AddBookingByPractitioner, resetAddBookingByPractitioner } from '../../Actions/Practitioner/AddBookingByPractitionerAction.js';
import { AddOTFBookingByPractitioner } from '../../Actions/Practitioner/AddOTFBookingByPractitionerAction.js';
import Loader from './Loader.js';


const generateTimeOptions = () => {
    const times = [];
    const start = new Date();
    start.setHours(0, 0, 0, 0);

    for (let i = 0; i <= 47; i++) {
        const time = new Date(start);
        time.setMinutes(start.getMinutes() + i * 30);
        times.push(time);
    }

    return times;
};

const timeOptions = generateTimeOptions();

const OTFBooking = ({ open, onClose, currentUid, selectedContact, onSendOtf }) => {
    const defaultValue = dayjs();
    const [sessionTypes, setSessionTypes] = useState([]);
    const addBookingStatus = useSelector((state) => state.addBookingByPractitioner.addBooking);
    console.log(addBookingStatus, "add booking status");
    
    const dispatch = useDispatch();
    const [date, setDate] = useState(defaultValue);
    const [Availability, setAvailability] = useState([]);
    const [day, setDay] = useState(null);
    const [selectedAvailability, setSelectedAvailability] = useState(null);
    const [start, setStart] = useState(null);
    const [end, setEnd] = useState(null);
    const [service, setService] = useState(null);
    const [selectedSchedule, setSelectedSchedule] = useState(null);
    const [bookingType, setBookingType] = useState("");
    const practitioner = useSelector((state) => state.practitioner.practitioner);
    const schedule = useSelector((state) => state.getSchedules.getSchedules);
    const [otfStartTime, setOtfStartTime] = useState(null);
    const [otfEndTime, setOtfEndTime] = useState(null);
    const [otfDate, setOtfDate] = useState(new Date(date));

    const [selectedToggle, setSelectedToggle] = useState("schedule");
    const [bookingDetails, setBookingDetails] = useState(null);

    const handleToggleChange = (event, newSelection) => {
        if (newSelection !== null) {
            setSelectedToggle(newSelection);
            setBookingType("");
        }
    };

    const handleDateChange = (newDate) => {
        setDate(newDate);
        setOtfDate(new Date(newDate));
        setOtfStartTime(null);
        setOtfEndTime(null);
    };
    const findAvailableSlotsForDate = (date) => {
        if (schedule && schedule !== "isLoading" && schedule.result) {
            for (const entry of schedule.result) {
                for (const request of entry.updateScheduleSlotRequests) {
                    let reqDay = new Date(request.slotDate).setHours(0, 0, 0, 0, 0);
                    let sentDay = new Date(date).setHours(0, 0, 0, 0, 0);
                    if (reqDay === sentDay) {
                        setSelectedSchedule(entry);
                        return request.slotTimes.filter(slot =>
                            slot.bookingStatus === "Open"
                        );
                    }
                }
            }
        }
        return [];
    };
    const setSelectedDay = (date) => {
        setDay(date);
        const avail = findAvailableSlotsForDate(date);
        setAvailability(avail);
        setStart(null);
        setEnd(null);
    };

    useEffect(() => {
        setSelectedDay(date);
    }, [date]);
    useEffect(() => {
        if (
            addBookingStatus && 
            addBookingStatus !== "isLoading" && 
            addBookingStatus.status === true &&
            addBookingStatus.result.length !== 0
        ) {
            const title = `Invitation - ${(service?.name || "Service")} - ${(selectedContact?.name || "Contact")}`;
        
            onSendOtf({
                bookingStartTime: addBookingStatus.result[0].bookingStartTime,
                bookingEndTime: addBookingStatus.result[0].bookingEndTime,
                otfBookingId: addBookingStatus.result[0].id,
                title: `Invitation - ${addBookingStatus.result[0].serviceName} - ${addBookingStatus.result[0].fullName}`,
                otfBookingType : addBookingStatus.result[0].bookingType == 0? "Online" : "On-Site"
            });
    
          
            onClose();
            dispatch(resetAddBookingByPractitioner());
        }
    }, [addBookingStatus, bookingDetails, service, selectedContact]);

    useEffect(() => {
        if (!service) {
            setSessionTypes([
                { value: '', label: 'Select Service First', disabled: true }
            ]);
        } else {
            if (service.type === 0) {
                setSessionTypes([
                    { value: 3, label: 'Online' }
                ]);
            } else if (service.type === 1) {
                setSessionTypes([
                    { value: 1, label: 'On-Site' }
                ]);
            } else if (service.type === 2) {
                setSessionTypes([
                    { value: 3, label: 'Online' },
                    { value: 1, label: 'On-Site' }
                ]);
            }
        }
    }, [service]);

    const handleSetAvailability = (slot) => {
        setSelectedAvailability(slot);
        setStart(null);
        setEnd(null);
    };

    const getFilteredEndTimes = (startTime) => {
        if (!startTime) return [];

        const startDate = new Date(startTime);
        const twoHoursAfterStart = new Date(
            startDate.getTime() + 2 * 60 * 60 * 1000
        );
        const parsedSlotEnd = parseTime(selectedAvailability.slotEnd);

        const effectiveEndTime =
            parsedSlotEnd < twoHoursAfterStart ? parsedSlotEnd : twoHoursAfterStart;

        return timeOptions.filter((time) => {
            const timeDate = new Date(time);
            const duration = (timeDate - startDate) / (1000 * 60);

            return duration >= 30 && timeDate <= effectiveEndTime;
        });
    };

    const parseTime = (timeString) => {
        const [hours, minutes, seconds] = timeString.split(":").map(Number);
        const date = new Date();
        date.setHours(hours, minutes, seconds, 0);
        return date;
    };

    const getFilteredStartTimes = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const currentTime = new Date();
        const filteredTimes = [];

        const parseTime = (timeString) => {
            const [hours, minutes, seconds] = timeString.split(":").map(Number);
            const date = new Date();
            date.setHours(hours, minutes, seconds, 0);
            return date;
        };

        if (selectedAvailability) {
            const slotStart = parseTime(selectedAvailability.slotStart);
            const slotEnd = parseTime(selectedAvailability.slotEnd);

            const slotEndMinus30 = new Date(slotEnd.getTime() - 30 * 60 * 1000);

            for (const time of timeOptions) {
                if (time >= slotStart && time <= slotEndMinus30) {
                    filteredTimes.push(time);
                }
            }
        }
        return filteredTimes;
    };
    const handleSlotChange = (startTime) => {
        setStart(startTime);
    };
    const handleSlotChangeEnd = (endTime) => {
        setEnd(endTime);
    };

    const formatTime12h = (date) => format(date, "hh:mm a");
    const handleServiceChange = (event) => {
        const selectedServiceId = event.target.value;
       
        const selectedService = practitioner.result[0].service.find(service => service.id === selectedServiceId);
        setService(selectedService);
        setBookingType("");
    };
    const handleSessionTypeChange = (event) => {
        setBookingType(event.target.value);
    };
    const handleCreateBooking = () => {
        let body = {}; 
    
        if (selectedToggle === "schedule") {
            const startTime = new Date(start);
            const endTime = new Date(end);
            const selected = new Date(day);
            startTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());
            endTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());
    
            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            const adjustedStartTime = new Date(startTime.getTime() - timezoneOffset);
            const adjustedEndTime = new Date(endTime.getTime() - timezoneOffset);
    
            body = {
                consumerId: selectedContact.id,
                practitionerId: currentUid,
                practitionerScheduleId: selectedSchedule.scheduleId,
                slotId: selectedAvailability.slotId,
                serviceId: service.id,
                bookingStartTime: adjustedStartTime,
                bookingEndTime: adjustedEndTime,
                bookingType: bookingType === 3 ? 0 : bookingType
            };
    
            dispatch(AddBookingByPractitioner(body));
        } else if (selectedToggle === "otf") {
            const startTime = new Date(otfStartTime);
            const endTime = new Date(otfEndTime);
            const selected = new Date(day);
            startTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());
            endTime.setFullYear(selected.getFullYear(), selected.getMonth(), selected.getDate());
    
            const timezoneOffset = new Date().getTimezoneOffset() * 60000;
            const adjustedStartTime = new Date(startTime.getTime() - timezoneOffset);
            const adjustedEndTime = new Date(endTime.getTime() - timezoneOffset);
    
            body = {
                consumerId: selectedContact.id,
                practitionerId: currentUid,
                serviceId: service.id,
                bookingStartTime: adjustedStartTime,
                bookingEndTime: adjustedEndTime,
                bookingType: bookingType === 3 ? 0 : bookingType
            };
    
            dispatch(AddOTFBookingByPractitioner(body));
        }
        setBookingDetails(body);
       
    };
    
    const getFilteredOtfStartTimes = () => {
        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const currentTime = new Date();
        const filteredTimes = [];
        const day = otfDate;
        day.setHours(0, 0, 0, 0);

        if (day.getTime() === currentDate.getTime()) {
            const oneHourAhead = new Date(currentTime.getTime() + 60 * 60 * 1000);
            for (const time of timeOptions) {
                if (time >= oneHourAhead) {
                    filteredTimes.push(time);
                }
            }
        } else {

            filteredTimes.push(...timeOptions);
        }

        return filteredTimes;
    };

    const handleOtfStartTimeChange = (startTime) => {
        setOtfStartTime(startTime);
    };
    const handleOtfEndTimeChange = (endTime) => {
        setOtfEndTime(endTime);
    };
    const getFilteredOtfEndTimes = (otfStartTime) => {
        if (!otfStartTime) return [];
        const startDate = new Date(otfStartTime);
        const twoHoursAfterStart = new Date(startDate.getTime() + 2 * 60 * 60 * 1000);
        return timeOptions.filter((time) => {
            const timeDate = new Date(time);
            const duration = (timeDate - startDate) / (1000 * 60);
            return duration >= 30 && timeDate <= twoHoursAfterStart;
        });
    };
    const handleCancel = () => {
        onClose();
    };
    const isDisabled =
        (selectedToggle === 'schedule' && (!start || !end || bookingType === "" || !service)) ||
        (selectedToggle === 'otf' && (!otfStartTime || !otfEndTime || !bookingType || !service));
    return (
        <Dialog
            open={open}
            onClose={onClose}
            maxWidth="sm"
            fullWidth
            sx={{
                '& .MuiDialog-paper': {
                    width: '692px',
                    maxWidth: '100%',
                    borderRadius: '8px',
                },
            }}
        >
            <DialogContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '24px',
                    overflow: 'hidden',
                }}
            >
                {/* Your custom div with specified styles */}
                <div className="w-full gap-[24px] flex flex-col item-center justify-center">
                    <div className="w-full  flex flex-row item-start justify-between">
                        <div className="flex flex-col w-[588px] item-start justify-start">
                            <h3 className="m-0 font-web-primary-h3-primary-dm-sans text-xl">Send Booking Invite</h3>
                            <p className="max-w-[337px] mb-0 text-sm font-web-primary-h3-primary-dm-sans">
                                Create an ‘On The Fly’ booking according to mutual discussion between you and the customer.
                            </p>
                        </div>
                        <div className="flex flex-col w-[24px] h-[24px]">
                            <IconButton
                                color="inherit"
                                onClick={onClose}
                                aria-label="close"
                            >
                                <CancelOutlinedIcon />
                            </IconButton>
                        </div>
                    </div>
                    <div className='flex flex-col gap-[24px] w-full'>
                        <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
                            <InputLabel id="demo-simple-select-standard-label">Service</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={service?.serviceId}
                                onChange={handleServiceChange}
                                label="Service"
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 150,
                                        },
                                    },
                                }}
                            >
                                {practitioner && practitioner.result && practitioner.result.length > 0 &&
                                    practitioner.result[0].service.length > 0 &&
                                    practitioner.result[0].service
                                        .filter(service => service.isActive)
                                        .sort((a, b) => a.name.localeCompare(b.name))
                                        .map((service) => (
                                            <MenuItem key={service.id} value={service.id}>
                                                {service.name}
                                            </MenuItem>
                                        ))
                                }
                                {practitioner && practitioner.result && practitioner.result.length > 0 &&
                                    practitioner.result[0].service.length == 0 &&
                                    (<MenuItem>
                                        No Service Available
                                    </MenuItem>)
                                }
                            </Select>
                        </FormControl>

                        <CustomDatePicker
                            value={date}
                            onChange={handleDateChange} />
                        <div className='h-[46px] rounded-[32px] bg-[#F0F0F0] flex item-center justify-center p-[4px]'>
                            <ToggleButtonGroup
                                value={selectedToggle}
                                exclusive
                                onChange={handleToggleChange}
                                className="w-full rounded-[24px]"
                            >
                                <ToggleButton
                                    value="schedule"
                                    sx={{
                                        flex: 1,
                                        borderRadius: '24px',
                                        textTransform: 'none',
                                        border: 'none',
                                        backgroundColor: selectedToggle === 'schedule' ? 'white !important' : 'transparent',
                                        color: selectedToggle === 'schedule' ? 'black !important' : 'inherit',
                                        borderColor: selectedToggle === 'schedule' ? 'transparent' : 'rgba(0, 0, 0, 0.12)',
                                        '&:hover': {
                                            backgroundColor: selectedToggle === 'schedule' ? 'white !important' : 'rgba(0, 0, 0, 0.08)',
                                        },
                                    }}
                                >
                                    Your Schedule
                                </ToggleButton>
                                <ToggleButton
                                    value="otf"
                                    sx={{
                                        flex: 1,
                                        borderRadius: '24px',
                                        textTransform: 'none',
                                        border: 'none',
                                        backgroundColor: selectedToggle === 'otf' ? 'white !important' : 'transparent',
                                        color: selectedToggle === 'otf' ? 'black !important' : 'inherit',
                                        borderColor: selectedToggle === 'otf' ? 'transparent' : 'rgba(0, 0, 0, 0.12)',
                                        '&:hover': {
                                            backgroundColor: selectedToggle === 'otf' ? 'white !important' : 'rgba(0, 0, 0, 0.08)',
                                        },
                                    }}
                                >
                                    On The Fly
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <div className='w-full flex gap-[24px]'>
                            <div className='w-full flex flex-col gap-[24px]'>
                                {selectedToggle === "schedule" && (
                                    <>
                                        <div className='w-full min-h-[60px] flex flex-col gap-[8px]'>
                                            <h6 className='m-0 h-[20px] font-web-primary-h3-primary-dm-sans text-sm'>Availability</h6>
                                            <div className='flex flex-row gap-[8px]'>
                                                {Availability.map((slot) => (
                                                    <button
                                                        key={slot.slotId}
                                                        className={`cursor-pointer py-0.5 px-[3px] rounded overflow-hidden flex flex-row items-center justify-start border-[1px] border-solid ${selectedAvailability &&
                                                            slot.slotId === selectedAvailability.slotId
                                                            ? "bg-[#048D8D] border-transparent"
                                                            : "bg-transparent border-[#048D8D]"
                                                            }`}
                                                        onClick={() => handleSetAvailability(slot)}
                                                    >
                                                        <div className="rounded-81xl bg-primary-dark hidden flex-col items-center justify-center relative">
                                                            <div className="w-2 h-2 absolute !m-[0] right-[-2px] bottom-[-2px] rounded-45xl bg-neutral-1 hidden z-[0]">
                                                                <div className="absolute h-full w-full top-[0%] right-[0%] bottom-[0%] left-[0%] rounded-81xl bg-success-main overflow-hidden flex flex-row items-start justify-start">
                                                                    <div className="h-2 w-2 relative rounded-81xl" />
                                                                </div>
                                                            </div>
                                                            <div className="w-6 h-6 relative [transform:_rotate(-90deg)] z-[2]" />
                                                        </div>
                                                        <b
                                                            className={`relative text-xs leading-[18px] uppercase font-web-primary-h3-primary-dm-sans text-left whitespace-nowrap ${selectedAvailability &&
                                                                slot.slotId === selectedAvailability.slotId
                                                                ? "text-white"
                                                                : "text-[#048D8D]"
                                                                }`}
                                                        >
                                                            {new Date(
                                                                `2000-01-01T${slot.slotStart}`
                                                            ).toLocaleTimeString([], {
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                                hour12: true,
                                                            })}{" "}
                                                            -{" "}
                                                            {new Date(
                                                                `2000-01-01T${slot.slotEnd}`
                                                            ).toLocaleTimeString([], {
                                                                hour: "numeric",
                                                                minute: "numeric",
                                                                hour12: true,
                                                            })}
                                                        </b>
                                                        <img
                                                            className="h-6 w-6 relative rounded-81xl overflow-hidden shrink-0 hidden min-h-[24px]"
                                                            alt=""
                                                            src="/cancelfilled1.svg"
                                                        />
                                                    </button>
                                                ))}
                                            </div>
                                            {Availability && Availability.length === 0 && (
                                                <p className='mb-20 text-sm font-web-primary-h3-primary-dm-sans'>
                                                    No slots available, you can create an OTF(on the fly) booking.
                                                </p>
                                            )}

                                        </div>
                                        {Availability && Availability.length > 0 && (
                                            <>
                                                <div className='w-full flex flex-row gap-[24px]'>
                                                    <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Start Time</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Start Time"
                                                            value={start ? start : ""}
                                                            onChange={(event) =>
                                                                handleSlotChange(event.target.value)
                                                            }
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 140,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {getFilteredStartTimes().map((time) => (
                                                                <MenuItem key={time} value={time}>
                                                                    {formatTime12h(time)}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                    <FormControl variant="standard" sx={{ minWidth: 220, width: '100%' }}>
                                                        <InputLabel id="demo-simple-select-standard-label">End Time</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="End Time"
                                                            value={end ? end : ""}
                                                            onChange={(event) =>
                                                                handleSlotChangeEnd(event.target.value)
                                                            }
                                                            MenuProps={{
                                                                PaperProps: {
                                                                    style: {
                                                                        maxHeight: 140,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {getFilteredEndTimes(start).map((time) => (
                                                                <MenuItem key={time} value={time}>
                                                                    {formatTime12h(time)}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                                <div className='h-[53px flex flex-row'>
                                                    <FormControl variant="standard" sx={{ minWidth: 220, width: '100%' }}>
                                                        <InputLabel id="demo-simple-select-standard-label">Session Type</InputLabel>
                                                        <Select
                                                            labelId="demo-simple-select-standard-label"
                                                            id="demo-simple-select-standard"
                                                            label="Session Type"
                                                            value={bookingType}
                                                            onChange={handleSessionTypeChange}

                                                        >
                                                            {sessionTypes.map((type) => (
                                                                <MenuItem key={type.value} value={type.value}>
                                                                    {type.label}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </div>
                                            </>
                                        )}

                                    </>
                                )}
                                {selectedToggle === "otf" && (
                                    <>
                                        <div className='w-full flex flex-row gap-[24px]'>
                                            <FormControl variant="standard" sx={{ minWidth: 120, width: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">Start Time</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Start Time"
                                                    value={otfStartTime}
                                                    onChange={(event) => handleOtfStartTimeChange(event.target.value)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 140,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {getFilteredOtfStartTimes().map(
                                                        (time) => (
                                                            <MenuItem key={time} value={time}>
                                                                {formatTime12h(time)}
                                                            </MenuItem>
                                                        )
                                                    )}
                                                </Select>
                                            </FormControl>
                                            <FormControl variant="standard" sx={{ minWidth: 220, width: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">End Time</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="End Time"
                                                    value={otfEndTime}
                                                    onChange={(event) => handleOtfEndTimeChange(event.target.value)}
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                maxHeight: 140,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {getFilteredOtfEndTimes(otfStartTime).map((time) => (
                                                        <MenuItem key={time} value={time}>
                                                            {formatTime12h(time)}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                        <div className='h-[53px flex flex-row'>
                                            <FormControl variant="standard" sx={{ minWidth: 220, width: '100%' }}>
                                                <InputLabel id="demo-simple-select-standard-label">Session Type</InputLabel>
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    label="Session Type"
                                                    value={bookingType}
                                                    onChange={handleSessionTypeChange}
                                                >
                                                    {sessionTypes.map((type) => (
                                                        <MenuItem key={type.value} value={type.value}>
                                                            {type.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </>
                                )}
                            </div>

                        </div>
                    </div>
                    <div className='h-[34px] flex flex-row justify-end item center gap-[16px]'>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#F5F5F5',
                                textTransform: 'none',
                                color: "#262626",
                                '&:hover': {
                                    backgroundColor: '#F5F5F5'
                                }
                            }}
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="contained"
                            sx={{
                                backgroundColor: '#006A6A',
                                width: "138px",
                                textTransform: 'none',
                                '&:hover': {
                                    backgroundColor: '#004d4d'
                                }
                            }}

                            disabled={isDisabled}

                            onClick={() => {
                                handleCreateBooking();
                              
                              }}
                        >
                            {addBookingStatus === "isLoading" ? (
                                <Loader />
                            ) : (
                                "Create Booking"
                            )}
                        </Button>
                    </div>

                </div>
            </DialogContent>
        </Dialog >
    );
};

export default OTFBooking;